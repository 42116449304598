import React, { useState, useEffect } from 'react';
import { Box, IconButton, Paper, TextField, Button, Typography } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import { db } from './firebaseConfig';
import { collection, doc, updateDoc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([{ text: 'How can I help you?', sender: 'bot' }]);
    const [input, setInput] = useState('');
    const [sessionId, setSessionId] = useState(uuidv4());

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 10000); // 10 seconds

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const docRef = doc(db, "chat_sessions", sessionId);

        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                setMessages(doc.data().messages);
            }
        });

        return () => unsubscribe();
    }, [sessionId]);

    const toggleContactUs = () => {
        setIsOpen(!isOpen);
    };

    const handleSendMessage = async () => {
        if (input.trim()) {
            const newMessage = { text: input, sender: 'user', timestamp: new Date() };
            const docRef = doc(db, "chat_sessions", sessionId);
            const docSnapshot = await getDoc(docRef);

            if (docSnapshot.exists()) {
                await updateDoc(docRef, {
                    messages: [...docSnapshot.data().messages, newMessage]
                });
            } else {
                await setDoc(docRef, { messages: [newMessage] });
            }

            setInput('');
        }
    };

    return (
        <Box sx={{ position: 'fixed', bottom: 24, right: 24, zIndex: 1000 }}>
            <IconButton
                color="primary"
                onClick={toggleContactUs}
                sx={{ backgroundColor: '#FFB300', '&:hover': { backgroundColor: '#FFA000' }, width: '48px', height: '48px' }}
            >
                {isOpen ? <CloseIcon /> : <ChatIcon />}
            </IconButton>
            {isOpen && (
                <Paper sx={{ width: 300, height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 2, marginTop: 1, boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)' }}>
                    <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: 2 }}>
                        {messages.map((message, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    textAlign: message.sender === 'user' ? 'right' : 'left',
                                    backgroundColor: message.sender === 'user' ? '#FF8F00' : '#29B6F6',
                                    color: '#fff',
                                    borderRadius: 2,
                                    padding: 1,
                                    marginBottom: 1,
                                    fontSize: '16px',
                                }}
                            >
                                {message.text}
                            </Typography>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                            sx={{ fontSize: '16px' }}
                        />
                        <Button variant="contained" onClick={handleSendMessage} sx={{ marginLeft: 1, backgroundColor: '#29B6F6', fontSize: '16px' }}>
                            Send
                        </Button>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="body2" sx={{ textAlign: 'center', fontSize: '14px' }}>
                            For text support, contact us at: <br />
                            <strong>(123) 456-7890</strong>
                        </Typography>
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default Chatbot;