import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import LanguageIcon from '@mui/icons-material/Language';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

const useIntersectionObserver = (options) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [options]);

    return [ref, isVisible];
};

const Dashboard = () => {
    const [storeIconRef, storeIconVisible] = useIntersectionObserver({ threshold: 0.1 });
    const [languageIconRef, languageIconVisible] = useIntersectionObserver({ threshold: 0.2 });
    const [socialIconRef, socialIconVisible] = useIntersectionObserver({ threshold: 0.3 });
    const [phoneIconRef, phoneIconVisible] = useIntersectionObserver({ threshold: 0.4 });

    return (
        <Box sx={{ flexGrow: 1, marginBottom: '80px' }}>
            <Typography variant="h3" component="h1" gutterBottom sx={{marginTop: "40px", fontSize: "1.5rem", fontFamily: "Geist Mono, monospace!important", }}>
                We Are
                <Typography variant="h4" component="h1" gutterBottom sx={{fontFamily: "Sacramento", color: "#FFB300", fontSize: "48px", lineHeight: "48px"}}>
                ...here to resolve issues and save you money by handling &#8623;
                </Typography>
            </Typography>
            <Grid container spacing={2} sx={{ padding: 4 }}>
                <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: "64px", }}>
                    <Paper
                        ref={storeIconRef}
                        sx={{
                            padding: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            transform: storeIconVisible ? 'translateY(0)' : 'translateY(50px)',
                            opacity: storeIconVisible ? 1 : 0,
                            transition: 'all 0.5s ease-in-out',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
                                transition: 'box-shadow 0.3s ease-in-out'
                            }
                        }}
                    >
                        <StoreIcon sx={{ fontSize: '3rem', marginBottom: 2 }} />
                        <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>Webstores</Typography>
                        <Typography variant="body1">
                        Transform your business with a custom-built online store. Our solutions help you reach a wider audience, increase sales, and provide a seamless shopping experience. Easily manage inventory, track orders, and analyze sales data with our user-friendly platform.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: "64px", }}>
                    <Paper
                        ref={languageIconRef}
                        sx={{
                            padding: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            transform: languageIconVisible ? 'translateY(0)' : 'translateY(50px)',
                            opacity: languageIconVisible ? 1 : 0,
                            transition: 'all 0.5s ease-in-out',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
                                transition: 'box-shadow 0.3s ease-in-out'
                            }
                        }}
                    >
                        <LanguageIcon sx={{ fontSize: '3rem', marginBottom: 2 }} />
                        <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>Managed Websites</Typography>
                        <Typography variant="body1">
                        Focus on your strengths while we manage your website. Our services keep your site up-to-date, secure, and optimized for performance. We handle everything from content updates to technical maintenance, letting you concentrate on business growth.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: "64px", }}>
                    <Paper
                        ref={socialIconRef}
                        sx={{
                            padding: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            transform: socialIconVisible ? 'translateY(0)' : 'translateY(50px)',
                            opacity: socialIconVisible ? 1 : 0,
                            transition: 'all 0.5s ease-in-out',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
                                transition: 'box-shadow 0.3s ease-in-out'
                            }
                        }}
                    >
                        <SocialDistanceIcon sx={{ fontSize: '3rem', marginBottom: 2 }} />
                        <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>Managed Social + Ads</Typography>
                        <Typography variant="body1">
                        Boost your brand with our social media and advertising management. We create targeted campaigns to engage your audience, drive traffic, and generate leads. Our experts manage your accounts, craft compelling content, and optimize ad spend for maximum ROI.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: "64px", }}>
                    <Paper
                        ref={phoneIconRef}
                        sx={{
                            padding: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            transform: phoneIconVisible ? 'translateY(0)' : 'translateY(50px)',
                            opacity: phoneIconVisible ? 1 : 0,
                            transition: 'all 0.5s ease-in-out',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
                                transition: 'box-shadow 0.3s ease-in-out'
                            }
                        }}
                    >
                        <PhoneAndroidIcon sx={{ fontSize: '3rem', marginBottom: 2 }} />
                        <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>Mobile App Development</Typography>
                        <Typography variant="body1">
                        Set your business apart with a mobile app. It showcases innovation, customer focus, and a commitment to embracing new technologies, ensuring the best experience for your customers.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;