import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, MenuItem, Fab, Tooltip } from '@mui/material';
import { collection, addDoc } from 'firebase/firestore'; 
import { db } from './firebaseConfig';
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Popup = () => {
    const [open, setOpen] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        intent: '',
    });

    useEffect(() => {
        const handleScroll = () => {
            if (!timerStarted) {
                setTimerStarted(true);
                const timer = setTimeout(() => {
                    setOpen(true);
                }, 20000); // 20 seconds

                return () => clearTimeout(timer);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [timerStarted]);

    useEffect(() => {
        const tooltipTimer = setTimeout(() => {
            setTooltipOpen(true);
        }, 5000); // 5 seconds

        return () => clearTimeout(tooltipTimer);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => { 
        e.preventDefault();
        
        // Add timestamp to formData
        const timestamp = new Date().toLocaleString();
        const dataWithTimestamp = { ...formData, timestamp };

        try { 
            const docRef = await addDoc(collection(db, "submissions"), dataWithTimestamp); 
            console.log("Document written with ID: ", docRef.id); 
            
            // Show a toast notification
            toast.success("Form submitted successfully!");

            // Clear form data
            setFormData({
                fullName: '',
                email: '',
                intent: '',
            });

            // Close the popup 
            handleClose();

        } catch (error) { 
            console.error("Error adding document: ", error);
            toast.error("Error submitting form. Please try again.");
        }
    };

    const handleFabClick = () => {
        setOpen(true);
        setTooltipOpen(false); // Close the tooltip when the FAB is clicked
    };

    return (
        <>
            {!open && (
                <Tooltip title="Contact Us" sx={{fontSize: '18px'}} open={tooltipOpen} onClose={() => setTooltipOpen(false)} onOpen={() => setTooltipOpen(true)}>
                    <Fab
                        color="primary"
                        aria-label="message"
                        onClick={handleFabClick}
                        sx={{
                            position: 'fixed',
                            bottom: 48,
                            right: 48,
                            backgroundColor: '#29B6F6',
                            '&:hover': {
                                backgroundColor: '#0288D1',
                            },
                        }}
                    >
                        <MessageIcon sx={{ color: '#FFB300' }} />
                    </Fab>
                </Tooltip>
            )}
            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { boxShadow: 24, borderRadius: 2, minWidth: '300px', margin: 'auto' } }}>
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                <DialogTitle>
                    <Typography className='brand-font primary'>Welcome to lightweight</Typography>
                    <Typography>How Can We Best Serve You</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#616161', // Inactive outline color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#29B6F6', // Hover outline color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#29B6F6', // Active outline color
                                    },
                                },
                            }}
                        />
                        <TextField
                            label="Email Address"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#616161', // Inactive outline color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#29B6F6', // Hover outline color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#29B6F6', // Active outline color
                                    },
                                },
                            }}
                        />
                        <TextField
                            label="I need..."
                            name="intent"
                            select
                            value={formData.intent}
                            onChange={handleChange}
                            required
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#616161', // Inactive outline color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#29B6F6', // Hover outline color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#29B6F6', // Active outline color
                                    },
                                },
                            }}
                        >
                            <MenuItem value="We Need A Freelance Consultant">We Need A Freelance Consultant</MenuItem>
                            <MenuItem value="I Want To Talk About My Business">I Want To Talk About My Business</MenuItem>
                            <MenuItem value="We Need Creative Content">We Need Creative Content</MenuItem>
                            <MenuItem value="We Need Motion Design">We Need Motion Design</MenuItem>
                            <MenuItem value="We Need A Mobile App">We Need A Mobile App</MenuItem>
                            <MenuItem value="We Need A Logo">We Need A Logo</MenuItem>
                            <MenuItem value="We Need An Event Planning Coordinator">We Need An Event Planning Coordinator</MenuItem>
                            <MenuItem value="We Need A Website Manager">We Need A Website Manager</MenuItem>
                            <MenuItem value="We're Ready For A New Website">We're Ready For A New Website</MenuItem>
                            <MenuItem value="We Want To Launch Our Web Store">We Want To Launch Our Web Store</MenuItem>
                            <MenuItem value="We'd Like A Freelance Social Media Manager">We'd Like A Freelance Social Media Manager</MenuItem>
                            <MenuItem value="We Need A Forward Thinking Marketing Strategist">We Need A Forward Thinking Marketing Strategist</MenuItem>
                            <MenuItem value="We Have A Lot Of Great Tools But They Don't Connect">We Have A Lot Of Great Tools But They Don't Connect</MenuItem>
                        </TextField>
                        <Button variant="contained" type="submit" sx={{ maxWidth: '250px', marginTop: '20px', backgroundColor: '#29B6F6' }}>
                            Submit
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Popup;