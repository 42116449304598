import React, { useState } from 'react';
import { AppBar, Typography, Toolbar, IconButton, List, ListItem, ListItemText, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useMediaQuery, useTheme } from '@mui/material';

const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const theme = useTheme();
    const isLargerThanMobile = useMediaQuery(theme.breakpoints.up('sm'));

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {['Home', 'About', 'Services', 'Contact'].map((text, index) => (
                    <a href={text == "Home" ? "/": text} className='navigation-items'>
                    <ListItem button key={text}>
                        <ListItemText primary={text} />
                    </ListItem>
                    </a>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar position="sticky" sx={{ backgroundColor: '#EEEEEE', }}>
            <Toolbar>
                <Box sx={{ flexGrow: 1, textAlign: 'left', }}>
                    <img className="logo" src='/logo.png' alt="Logo" />
                </Box>
                {/* <IconButton
                    edge="end"
                    color="#616161"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                >
                    {list()}
                </Drawer> */}
                <Box sx={{ flexGrow: 1, textAlign: 'right', }}>
                    {isLargerThanMobile && (
                        <a href="tel:+16822217208" style={{ textDecoration: 'none', }}>
                            <Typography sx={{color: "#FFB300", fontSize: "16px"}}>Call or Text 682-221-7208</Typography>
                        </a>
                    )}
                </Box>
                <IconButton
                    edge="end"
                    color="#616161"
                    aria-label="phone"
                    href="tel:+16822217208"
                >
                    <PhoneIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
