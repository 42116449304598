import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, List, ListItem, ListItemText } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const Recovery = () => {
    const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.1, triggerOnce: false });
    const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.2, triggerOnce: false });

    return (
        <Box sx={{ flexGrow: 1, marginTop: '80px', paddingBottom: '10px' }}>
            <Typography variant="h5" sx={{ fontSize: "1.5rem", color: "#29B6F6", marginTop: "80px", marginBottom: '40px' }}>
                Our Plan Is <Typography sx={{ fontFamily: "Sacramento!important", color: "#FFB300", fontSize: "56px" }}>Simple</Typography>
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={4}>
                    <Card
                        ref={ref1}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            backgroundColor: '#F5F5F5',
                            transform: inView1 ? 'translateY(0)' : 'translateY(100px)',
                            opacity: inView1 ? 1 : 0,
                            transition: 'all 0.6s ease-out'
                        }}
                    >
                        <CardMedia
                            component="img"
                            height="200"
                            image="/martech.jpg" // Replace with your image path
                            alt="Technologist work station"
                        />
                        <CardContent sx={{ padding: '40px', textAlign: 'center', flexGrow: 1 }}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Technology
                            </Typography>
                            <Typography variant="h6" component="h3" gutterBottom>We Implement Easy-To-Use Tools to Grow Your Business</Typography> 
                            <List> 
                                <ListItem> 
                                    <ListItemText primary="&#8620; Data-Powered Websites" /> 
                                </ListItem> 
                                <ListItem> 
                                    <ListItemText primary="&#8620; Easy-To-Manage Webstores" /> 
                                </ListItem>
                                <ListItem> 
                                    <ListItemText primary="&#8620; Custom Mobile Applications" /> 
                                </ListItem>
                                <ListItem> 
                                    <ListItemText primary="&#8620; Advanced Google Analytics 4 + Tag Manager Reporting" /> 
                                </ListItem> 
                                <ListItem> 
                                    <ListItemText primary="&#8620; A.I. Powered Products" /> 
                                </ListItem>
                                <ListItem> 
                                    <ListItemText primary="&#8620; CRM (Customer Relationship Management) Setup" /> 
                                </ListItem> 
                                <ListItem> 
                                    <ListItemText primary="&#8620; Creative Content - Copy + Graphic + Motion Design + Social + Storytelling" /> 
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card
                        ref={ref2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            backgroundColor: '#F5F5F5',
                            transform: inView2 ? 'translateY(0)' : 'translateY(100px)',
                            opacity: inView2 ? 1 : 0,
                            transition: 'all 0.6s ease-out'
                        }}
                    >
                        <CardMedia
                            component="img"
                            height="200"
                            image="/strategy.jpg" // Replace with your image path
                            alt="Strategy"
                        />
                        <CardContent sx={{ padding: '40px', textAlign: 'center', flexGrow: 1 }}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Strategy
                            </Typography>
                            <Typography variant="h6" component="h3" gutterBottom>Let Us Help You Target Your Type Of Customer</Typography>
                            <List>
                                <ListItem> 
                                    <ListItemText primary="&#8620; Experts At Analyzing Your Strengths, Weaknesses, Opportunities, and Threats (S.W.O.T)" /> 
                                </ListItem> 
                                <ListItem> 
                                    <ListItemText primary="&#8620; We Conduct Research - Competitor Analysis + Customer Psychographics" /> 
                                </ListItem>
                                <ListItem> 
                                    <ListItemText primary="&#8620; Let Us Help Determine Which Channels, Budget, & Ad Delivery Schedules Work For Your Type Of Business" /> 
                                </ListItem>
                                <ListItem> 
                                    <ListItemText primary="&#8620; We Will Manage Your Social Media Accounts From Creative To Content Calendars" /> 
                                </ListItem>
                                <ListItem> 
                                    <ListItemText primary="&#8620; You Run Your Business, We'll Manage Day-To-Day Marketing Activities" /> 
                                </ListItem>
                                <ListItem> 
                                    <ListItemText primary="&#8620; We Provide Reports On Your Marketing Campaign Efforts - KPI's Like ROAS (Return On Ad Spend), CPC (Cost Per Conversion), and Conversion Rates" /> 
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Recovery;