import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const AndroidCodeBackground = () => {

    return (
        <Box
            sx={{
                minHeight: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#212121',
                color: '#ffffff',
                fontFamily: 'monospace',
                overflow: 'hidden',
                position: 'relative',
                padding: '40px',
            }}
        >
            <video autoPlay loop muted style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: 1, opacity: 0.2}} >
                <source src="/dev-computer.mp4" type="video/mp4" /> Your browser does not support the video tag. 
            </video>
            <Box
                sx={{
                    backgroundColor: '#212121',
                    width: '100%',
                    textAlign: 'left',
                }}
            >
                <Typography variant="h5" sx={{ paddingLeft: "40px", fontSize: "1.5rem", color: "#29B6F6!important", marginTop: "48px", }}>We Build <Typography sx={{color: "#FFB300", fontSize: "48px!important", lineHeight: "48px", }}>Mobile Apps</Typography></Typography>
                <Typography variant="h5" sx={{ paddingLeft: "40px", fontSize: "1.5rem", color: "#29B6F6", marginTop: "20px", }}>"In today's fast-paced digital world, having a mobile app is no longer a luxury—it's a necessity. Our mobile app development services are designed to help small businesses like yours thrive in today's hyper-competitive market."</Typography>
                {/* <Button variant="contained" sx={{ marginTop: '40px', marginLeft: '40px', marginBottom: '80px', backgroundColor: '#29B6F6', textAlign: 'center!important'}}>
                    Learn More
                </Button> */}
            </Box> 
        </Box>
    );
};

export default AndroidCodeBackground;
