import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Stores = () => {

    return (
        <Box
            sx={{
                minHeight: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#212121',
                color: '#ffffff',
                fontFamily: 'monospace',
                overflow: 'hidden',
                position: 'relative',
                padding: '40px',
            }}
        >
            <video autoPlay loop muted style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: 1, opacity: 0.1}} >
                <source src="/checkout-time.mp4" type="video/mp4" /> Your browser does not support the video tag. 
            </video>
            <Box
                sx={{
                    backgroundColor: '#212121',
                    width: '100%',
                    textAlign: 'left',
                }}
            >
                <Typography variant="h5" sx={{ paddingLeft: "40px", fontSize: "1.5rem", fontColor: "#29B6F6", marginTop: "80px", }}>We Build <Typography sx={{color: "#FFB300", fontSize: "48px", lineHeight: "48px", }}>Custom Web Stores</Typography></Typography>
                <Typography variant="h5" sx={{ paddingLeft: "40px", fontSize: "1.5rem", fontColor: "#29B6F6", marginTop: "20px", }}>"With a webstore, your shop is open 24/7. Customers can browse and purchase your products at any time, leading to increased sales opportunities, even when your physical store is closed."</Typography>
                {/* <Button variant="contained" sx={{ marginTop: '40px', marginLeft: '40px', marginBottom: '80px', backgroundColor: '#29B6F6', textAlign: 'center!important'}}>
                    Learn More
                </Button> */}
            </Box> 
        </Box>
    );
};

export default Stores;
