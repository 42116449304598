import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#424242',
                color: '#ffffff',
                padding: '20px',
                textAlign: 'center',
                position: 'relative',
                bottom: 0,
                width: '100%',
                marginTop: '20px',
                boxSizing: 'border-box',
            }}
        >
            <Typography variant="body1">
                &copy; {new Date().getFullYear()} lightweight.work. All rights reserved.
            </Typography>
            {/* <Typography variant="body2">
                <Link href="/privacy-policy" color="inherit" sx={{ marginRight: 2 }}>
                    Privacy Policy
                </Link>
                <Link href="/terms-of-service" color="inherit">
                    Terms of Service
                </Link>
            </Typography> */}
        </Box>
    );
};

export default Footer;
